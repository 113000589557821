@use '@angular/material' as mat;

@include mat.core();

$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;

body {
	--bkg-color: white;
	--primary-text: #000000;

	--color-cancom-primary: #ff0035;
	--color-cancom-accent: #331f1f;
	--color-cancom-warn: #a60523;

	--color-cancom-grey: #333f4c;
	--color-cancom-lightblue: #e6ecf2;

	--color-cancom-links: #004d9c;
	--color-cancom-buttons: #1c66b0;

	--color-cancom-form: #565656;
	--color-cancom-form-hover: var(--primary-text);
	--color-cancom-form-error: #a60523;

	--color-cancom-red: #ff8f92;
	--color-cancom-yellow: #fcdd9f;
	--color-cancom-green: #d0e0c9;

	--service-list-color: #5e6c7b;
	--service-list-hover-color: #db0b2d;

	--navigation-bkg-color: var(--color-cancom-lightblue);
	--navigation-color: #5e6c7b;
	--navigation-title-color: #acb6bf;

	--dashboard-tile-bgk-color: var(--color-cancom-lightblue);
	--dashboard-news-bgk-color: #c4e0e8;

	--table-row-hover: lightgray;
	--table-row-icon-hover: darkgray;

	--loading-skeleton-background: rgb(239, 241, 246);
}

body.dark-theme {
	--bkg-color: #181a1b;
	--primary-text: #e8e6e3;

	--color-cancom-primary: #cc002a;
	--color-cancom-links: #6ebeff;

	--service-list-color: var(--primary-text);

	--navigation-bkg-color: #232627;
	--navigation-color: #a39c90;
	--navigation-title-color: #bab4ab;

	--dashboard-tile-bgk-color: #232627;
	--dashboard-news-bgk-color: #1c3e48;

	--mat-table-background-color: transparent;
	--mat-sidenav-scrim-color: #00000099;
	--mat-sidenav-content-text-color: var(--primary-text);

	--table-row-hover: #313537;
	--table-row-icon-hover: #494f52;

	--loading-skeleton-background: #232627;

	/* Dark Mode for NgxEditor */
	.NgxEditor {
		background: #181a1b;
		color: #e1e1e1;
		border: 2px solid rgba(255, 255, 255, 0.5);
	}

	.NgxEditor__MenuBar {
		background-color: #232627;
		color: #e1e1e1;
	}

	.NgxEditor--Disabled {
		opacity: 0.5;
		pointer-events: none;
	}

	.NgxEditor__Placeholder:before {
		color: #a9b1b7;
	}

	.NgxEditor__Content {
		background: #181a1b;
		color: #e1e1e1;
		padding: 8px;
	}

	.NgxEditor__Content p {
		margin: 0 0 10px;
		color: #e1e1e1;
	}

	.NgxEditor__Content blockquote {
		border-left: 3px solid #555;
	}

	.NgxEditor__Wrapper {
		border: 1px solid rgba(255, 255, 255, 0.2);
	}

	.NgxEditor__Wrapper .NgxEditor__MenuBar {
		background-color: #3b3b3b;
	}

	.NgxEditor__MenuItem:hover,
	.NgxEditor__Dropdown:hover {
		background-color: #454545;
	}

	.NgxEditor__MenuItem.NgxEditor__MenuItem--Active,
	.NgxEditor__MenuItem .NgxEditor__MenuItem--Active,
	.NgxEditor__Dropdown .NgxEditor__Dropdown--Selected,
	.NgxEditor__Dropdown .NgxEditor__Dropdown--Active,
	.NgxEditor__Dropdown .NgxEditor__Dropdown--Open {
		background-color: #565656;
		color: #e1e1e1;
	}

	.NgxEditor__Dropdown .NgxEditor__Dropdown--DropdownMenu {
		background-color: #181a1b;
	}

	.NgxEditor__Dropdown .NgxEditor__Dropdown--Item:hover {
		background-color: #3d3d3d;
	}

	.NgxEditor__Popup {
		background-color: #181a1b;
	}
}

$mat-cancom-primary: (
	50: #ffebef,
	100: #ffccd4,
	200: #fe969c,
	300: #f96b74,
	400: #ff3f50,
	500: #ff1a32,
	600: #ff0033,
	700: #ed002d,
	800: #e00025,
	900: #d30016,
	contrast: (
		50: $dark-primary-text,
		100: $dark-primary-text,
		200: $dark-primary-text,
		300: $dark-primary-text,
		400: $dark-primary-text,
		500: $light-primary-text,
		600: $light-primary-text,
		700: $light-primary-text,
		800: $light-primary-text,
		900: $light-primary-text,
	),
);

$mat-cancom-accent: (
	50: #eaeaea,
	100: #d0c9c8,
	200: #b3a4a2,
	300: #97807c,
	400: #826560,
	500: #6e4a45,
	600: #62423e,
	700: #523734,
	800: #432b2a,
	900: #331f1f,
	contrast: (
		50: $dark-primary-text,
		100: $dark-primary-text,
		200: $dark-primary-text,
		300: $dark-primary-text,
		400: $light-primary-text,
		500: $light-primary-text,
		600: $light-primary-text,
		700: $light-primary-text,
		800: $light-primary-text,
		900: $light-primary-text,
	),
);

$mat-cancom-warn: (
	50: #fce9ee,
	100: #f7c9d2,
	200: #e3939a,
	300: #d66a74,
	400: #df4754,
	500: #e3303d,
	600: #d4273b,
	700: #c21d34,
	800: #b5152d,
	900: #a60523,
	contrast: (
		50: $dark-primary-text,
		100: $dark-primary-text,
		200: $dark-primary-text,
		300: $dark-primary-text,
		400: $dark-primary-text,
		500: $light-primary-text,
		600: $light-primary-text,
		700: $light-primary-text,
		800: $light-primary-text,
		900: $light-primary-text,
	),
);

$cancom-app-primary: mat.m2-define-palette($mat-cancom-primary);
$cancom-app-accent: mat.m2-define-palette($mat-cancom-accent);
$cancom-app-warn: mat.m2-define-palette($mat-cancom-warn);

$custom-typography: mat.m2-define-typography-config(
	$font-family: 'Gotham, sans-serif',
);

$cancom-app-theme: mat.m2-define-light-theme(
	(
		color: (
			primary: $cancom-app-primary,
			accent: $cancom-app-accent,
			warn: $cancom-app-warn,
		),
		typography: $custom-typography,
		density: -1,
	)
);

$cancom-app-theme-dark: mat.m2-define-dark-theme(
	(
		color: (
			primary: $cancom-app-primary,
			accent: $cancom-app-accent,
			warn: $cancom-app-warn,
		),
	)
);

.background-primary {
	background-color: var(--color-cancom-primary);
}

.background-accent {
	background-color: var(--color-cancom-accent);
}

.color-primary {
	color: var(--color-cancom-primary);
}

.color-accent {
	color: var(--color-cancom-accent);
}

.color-warn {
	color: var(--color-cancom-warn);
}

// Apply the light theme by default
@include mat.all-component-themes($cancom-app-theme);
@include mat.typography-hierarchy($custom-typography);

// Apply the dark theme only when the user prefers dark themes.
.dark-theme {
	color-scheme: dark;
	@include mat.all-component-colors($cancom-app-theme-dark);
}
